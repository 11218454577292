import { useState } from "react";
import { handleFormSubmission } from "../Utilities/utils-functions";

const Form = ({ menuShowing, toggleMenu }) => {
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        message: ""
    });
    const [errorShowing, setErrorShowing] = useState(false);
    const [successShowing, setSuccessShowing] = useState(false);
    return (
        <div className='form-container'>
            <button 
                onClick={() => {toggleMenu(!menuShowing); setErrorShowing(false); setSuccessShowing(false);}} 
                className="form-container-button"
            >
                <i class="fa-solid fa-x fa-xl"></i>
            </button>
            <form>
                <div className="form-section">
                    <label className="form-label" for="full_name">Full Name:</label>
                    <input 
                        id="full_name"
                        name="full_name"
                        value={formData["full_name"]}
                        onChange={(evt) => {setFormData({ ...formData, [evt.target.name] : evt.target.value })}}
                        className="form-input"
                        required
                    />
                </div>  
                <div  className="form-section">
                    <label className="form-label" for="email">Email:</label>
                    <input 
                        id="email"
                        name="email"
                        value={formData["email"]}
                        onChange={(evt) => {setFormData({ ...formData, [evt.target.name] : evt.target.value })}}
                        className="form-input"
                        required
                    />
                </div>
                <div className="form-section">
                <label className="form-label" for="message">Message:</label>
                    <textarea 
                        id="message"
                        name="message"
                        value={formData["message"]}
                        onChange={(evt) => {setFormData({ ...formData, [evt.target.name] : evt.target.value })}}
                        placeholder="A brief description of the job"
                        className="form-textarea"
                        required
                    />
                </div>
                <div className="form-section">
                    { errorShowing && <p className="error">All input are required</p> }
                    { successShowing && <p className="success">Thank you for submitting your request. I will be in touch soon.</p>}
                </div>
                <div className="form-section">
                    <button 
                        onClick={(evt) => {
                            evt.preventDefault();
                            handleFormSubmission(setFormData, setSuccessShowing, setErrorShowing, formData);
                        }} 
                        className="form-button"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
};

export default Form;