import "./styles.css";
import { projectsCopy } from "../Utilities/projectsCopy";
import FoogleImages from '../Assets/Foogle-Images.png';
import FunkyQuote from "../Assets/Funky-Quote.png";
import IPTracker from "../Assets/IP-Tracker.jpg";

export const projectsRendering = projectsCopy.map((project) => {
    const technologiesRendering = project.technologies.map((technologie) => (<p>{technologie}</p>))
    const images = [FoogleImages, FunkyQuote, IPTracker];
    return (
        <div className="projectCardContainer">
            <a className="projectTitleLink" href={project.liveDemoLink} target="_blank" rel="noreferrer">{project.projectTitle}</a>
            <p className="projectSummary">{project.summary}</p>
            <p className="projectSummary">The technologies used to build this project are :</p>
            <div className="projectTechContainer">
                {technologiesRendering}
            </div>
            <p className="projectSummary">You can live demo the app <a href={project.liveDemoLink} target="_blank" rel="noreferrer">here</a> or access the code behind the project <a href={project.github} target="_blank" rel="noreferrer">here</a></p>
            <img src={images[projectsCopy.indexOf(project)]} alt="Cover for the project" className="projectImage" />
        </div>
    )
});


