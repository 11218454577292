import { validate } from "email-validator";

const formValidator = (formData) => {
    if (formData["full_name"] === "" || !validate(formData["email"]) || formData["message"] === "") return true;
    else return false;
}; 
export const handleFormSubmission = (setFormData, setSuccessShowing, setErrorShowing, formData) => {
    setErrorShowing(formValidator(formData));
    if (!formValidator(formData)) {
        setSuccessShowing(true);
        setFormData({
            full_name: "",
            email: "",
            message: ""
        });
    }
}

export const navbarLogic = (entry) => {
    const aboutLink = document.getElementById("aboutLink");
    const projectsLink = document.getElementById("projectsLink");
    const experiencesLink = document.getElementById("experiencesLink");
    if (entry.target.id === "about" && entry.isIntersecting) {
        aboutLink.classList.add("active");
        projectsLink.classList.remove("active");
        experiencesLink.classList.remove("active");
    } else if (entry.target.id === "about" && !entry.isIntersecting) {
        aboutLink.classList.remove("active");
        projectsLink.classList.add("active");
        experiencesLink.classList.remove("active");
    } else if (entry.target.id === "projects" && entry.isIntersecting) {
        aboutLink.classList.remove("active");
        projectsLink.classList.add("active");
        experiencesLink.classList.remove("active");
    } else if (entry.target.id === "experiences" && entry.isIntersecting) {
        aboutLink.classList.remove("active");
        projectsLink.classList.remove("active");
        experiencesLink.classList.add("active");
    }
};

export const downloadENResume = () => {
    fetch('English CV.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'English Résumé.pdf';
            alink.click();
        })
    })
};

export const downloadFRResume = () => {
    fetch('CV - Bell.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'English Résumé.pdf';
            alink.click();
        })
    })
};