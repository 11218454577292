import MobileVersion from './Mobile Version/Main';
import DesktopVersion from './Desktop Version/Main';

function App() {
  return (
    <>
      <DesktopVersion />
      <MobileVersion />
    </>
  );
}

export default App;
