import './styles.css';
import { experincesCopy } from '../Utilities/experiencesCopy';

export const experiencesRendering = experincesCopy.map((experience) => (
    <div className="experienceContainer">
        <p className="year">{experience.year}</p>
        <a className="link" href={experience.link} target="_blank" rel="noreferrer">
            <div className="jobTitleContainer">
                <p>{experience.jobTitle}</p>
                <p>-</p>
                <p>{experience.employer}</p>
            </div>
        </a>
        <p className="summary">{experience.summary}</p>
    </div>
));