import '../App.css';
import './styles.css';
import { useState } from 'react';
import { experiencesRendering } from '../Components/WorkExperienceCard';
import { projectsRendering } from '../Components/Project';
import Form from '../Components/Form';
import { downloadENResume, downloadFRResume, } from '../Utilities/utils-functions';
import MyLogo from '../Assets/My Logo.png';

const MobileVersion = () => {
    const [menuShowing, toggleMenu] = useState(false);
    return (
        <div className="mobile">
            <nav>
                <div className='d-flex gap-4 mb-3 align-items-centre justify-content-between'>
                    <img className="logoImg" src={MyLogo} alt="Nathan Logo" />
                    <button onClick={() => toggleMenu(!menuShowing)} className='menu-button'>Hire me</button>
                </div>
                {
                    menuShowing &&
                    <div className='form-container-mobile'>
                        <Form 
                            menuShowing={menuShowing}
                            toggleMenu= {toggleMenu}
                        />
                    </div>
                }
            </nav>
            <header>
                <div>
                    <h1>Nathan Lufuluabo</h1>
                    <h2>Software Developper</h2>
                    <p className="intro">I build accessible, inclusive products and <br></br> digital experiences for the web.</p>
                </div>
                <ul className="socials">
                    <li>
                        <a  href="https://github.com/natelufuluabo" target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-github logo"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/lufuluabo/" target="_blank" rel="noreferrer">
                            <i className="fa-brands fa-linkedin logo"></i>
                        </a>
                    </li>
                </ul>
            </header>
            <main className="content">
                <section id="about" className="about">
                    <p className="section-title">About me</p>
                    <div className="section-content">
                        <p className="about-text">
                        I am a results-driven Software Developer with a strong background in <span>sales and customer success.</span>
                        </p>
                        <p className="about-text">With expertise in generating sales appointments and driving client success, I have a <span>proven track record of delivering exceptional results.</span> My technical skills and customer-centric approach make me a valuable asset to any software development team.</p>
                        <p className="about-text">
                        As a Software Developer, I bring a unique blend of <span>technical expertise and customer-focused mindset</span>. I am proficient in various programming languages and frameworks, including Python, Django, JavaScript and React. <span>My strong communication skills, problem-solving abilities, and dedication to delivering high-quality software solutions</span> make me an ideal candidate for junior software development roles.
                        </p>
                    </div>
                </section>
                <section id="projects" className="projects">
                    <p className="section-title">Projects</p>
                    <div className="section-content">
                        {projectsRendering}
                    </div>
                </section>
                <section id="experiences" className="experience">
                    <p className="section-title">Experiences</p>
                    <div className="section-content">
                        {experiencesRendering}
                        <button className='resumeButton'>View Full Résumé <span className='languagesContainer'><span onClick={downloadENResume}>EN</span><span onClick={downloadFRResume}>FR</span></span></button>
                    </div>
                </section>
            </main>
            <footer>
                Built with <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a> and <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap CSS</a>, deployed with <a href="https://pages.cloudflare.com" target="_blank" rel="noreferrer">Cloudflare</a>. All text is set in the <a href="https://fonts.google.com/specimen/Inter?query=inter" target="_blank" rel="noreferrer">Inter</a> typeface.
            </footer>
        </div>
    )
};

export default MobileVersion;