export const experincesCopy = [
    {
        year: "Aug 2021 - Present",
        jobTitle: "Sales Development Representatives",
        employer: "nesto",
        summary: "Generated high-quality sales appointments for mortgage advisors through proactive phone outreach. By effectively engaging customers and building relationships, I consistently achieved outstanding results, contributing to approximately 10 million dollars of mortgage volume. My strategic approach to sales and ability to generate new business had a significant impact on the company's growth and overall success.",
        link: "https://www.nesto.ca/"
    },
    {
        year: "May 2021 - Nov 2021",
        jobTitle: "Business Client Manager",
        employer: "TELUS Health",
        summary: "Provided comprehensive support for the Babylon by TELUS Health mobile application via email and phone calls, ensuring smooth adoption and maximizing customer satisfaction. Collaborated closely with the development team to enhance the app's user experience based on customer feedback and requirements. Through proactive customer engagement and personalized assistance, played a vital role in increasing customer retention by 5%.",
        link: "https://www.babylonhealth.com/ca"
    },
    {
        year: "Oct 2019 - Feb 2021",
        jobTitle: "Technical Support Specialist",
        employer: "Concentrix",
        summary: "Resolved hardware and software issues for Apple device users by leveraging in-depth product knowledge and effective troubleshooting skills, consistently ensuring timely and effective issue resolution. Maintained an average customer satisfaction rating of 90% while providing support to customers.",
        link: "https://www.concentrix.com/"
    }
]

