import { useEffect } from 'react';
import './styles.css';
import MyLogo from '../Assets/My Logo.png';
import { experiencesRendering } from '../Components/WorkExperienceCard';
import { projectsRendering } from '../Components/Project';
import Form from '../Components/Form';
import { downloadENResume, downloadFRResume, navbarLogic } from '../Utilities/utils-functions';

const DesktopVersion = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => { entries.forEach((entry) => navbarLogic(entry)) },
            { rootMargin: "-200px" }
        );

        const about = document.getElementById('about');
        const projects = document.getElementById('projects');
        const experiences = document.getElementById('experiences');

        if (about) observer.observe(about);
        if (projects) observer.observe(projects);
        if (experiences) observer.observe(experiences);

        return () => observer.disconnect();
    }, []);
    
    return (
        <div className="desktop">
            <div className="container d-flex gap-5">
                <div className="col-6 d-flex flex-column justify-content-between desktop-left-container">
                    <header>
                        <div>
                            <div className='d-flex gap-4 mb-3 align-items-end'>
                                <img className="desktop-logoImg" src={MyLogo} alt="Nathan Logo" />
                            </div>
                            <div>
                                <h1 className='desktop-h1'>Nathan Lufuluabo</h1>
                                <h2 className='desktop-h2'>Software Developper</h2>
                                <p className="desktop-intro">I build accessible, inclusive products and <br></br> digital experiences for the web.</p>
                            </div>
                        </div>
                        <nav>
                            <ul className="d-flex flex-column gap-4">
                                <a id='aboutLink' href="#about">About</a>
                                <a id='projectsLink' href="#projects">Projects</a>
                                <a id='experiencesLink' href="#experiences">Experiences</a>
                                <a className='button' href="#Form">Hire me</a>
                            </ul>
                        </nav>
                    </header>
                    <ul className="desktop-socials">
                        <li>
                            <a  href="https://github.com/natelufuluabo" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-github desktop-logo"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/lufuluabo/" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-linkedin desktop-logo"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-6 d-flex flex-columnalign-items-center desktop-right-side">
                    <main className="d-flex flex-column">
                        <section id="about" className="desktop-about">
                            <div className="desktop-section-content">
                                <p className="desktop-about-text">
                                I am a results-driven Software Developer with a strong background in <span>sales and customer success.</span>
                                </p>
                                <p className="desktop-about-text">With expertise in generating sales appointments and driving client success, I have a <span>proven track record of delivering exceptional results.</span> My technical skills and customer-centric approach make me a valuable asset to any software development team.</p>
                                <p className="desktop-about-text">
                                As a Software Developer, I bring a unique blend of <span>technical expertise and customer-focused mindset</span>. I am proficient in various programming languages and frameworks, including Python, Django, JavaScript and React. <span>My strong communication skills, problem-solving abilities, and dedication to delivering high-quality software solutions</span> make me an ideal candidate for junior software development roles.
                                </p>
                            </div>
                        </section>
                        <section id="projects" className="projects">
                            <div className="section-content">
                                {projectsRendering}
                            </div>
                        </section>
                        <section id="experiences" className="desktop-experience">
                            <div className="desktop-section-content">
                                {experiencesRendering}
                                <button className='resumeButton'>View Full Résumé <span className='languagesContainer'><span onClick={downloadENResume}>EN</span><span onClick={downloadFRResume}>FR</span></span></button>
                            </div>
                        </section>
                        <section id="Form"><Form /></section>
                        <footer>
                            Built with <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a> and <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap CSS</a>, deployed with <a href="https://pages.cloudflare.com" target="_blank" rel="noreferrer">Cloudflare</a>. All text is set in the <a href="https://fonts.google.com/specimen/Inter?query=inter" target="_blank" rel="noreferrer">Inter</a> typeface.
                        </footer>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default DesktopVersion;