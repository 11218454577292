export const projectsCopy = [
    {
        projectTitle:"Foogle-Images",
        summary: "Powered by the the Unsplash Images API, this web app draws inspiration from Google Images and brings you an immersive experience that combines the power of search with the limitless beauty of Unplash's vast collection.",
        technologies: ["React", "Bootstrap", "Recoil", "Unsplash Images API", "Cloudflare pages"],
        liveDemoLink : "https://images-c7w.pages.dev/",
        github: "https://github.com/natelufuluabo/foogle-images",
    },
    {
        projectTitle:"Funky-Quote",
        summary: "Discover the Wisdom of the Ages! Dive into our treasure trove of timeless quotes that inspire, motivate, and illuminate. Whether you seek profound wisdom or a dose of humor, our curated collection of quotes has something for every mood and moment. Let words ignite your imagination and guide your journey through life's infinite possibilities. This project was completed for a customer",
        technologies: ["NextJS", "Sass", "Recoil", "Vercel"],
        liveDemoLink : "https://funky-quote.vercel.app",
        github: "https://github.com/natelufuluabo/Funky-Quote",
    },
    {
        projectTitle:"IP-Tracker",
        // summary: "This project was found on frontendmentor.io which is a website I use to find good challenging project to complete to sharpen my skills as a software developper. \n The goal of the project was to build an ip tracker web app and get familiar with geolocalisation technologies.",        
        summary: "Unlock Your Web's Mysteries with DomainMapper! Feed your curiosity by discovering where your website's server calls home. Just enter a domain, and watch the magic unfold as we unveil its server's location on a live map.  Explore the digital world in a whole new way!",
        technologies: ["React", "CSS", "Recoil", "Vercel", "LeafletJs", "Vite"],
        liveDemoLink : "https://ip-tracker-38s.pages.dev",
        github: "https://github.com/natelufuluabo/ip-tracker",
    },
]